import type { AssociatedJob } from 'api/types';
import { format } from 'date-fns';
import AWS from 'aws-sdk';
import config from 'config/index';

export function dateFormatter(date: string, param?: string /* dateFormat?: string*/) {
  const dateValue = date?.split('T');

  const isStr = /^[A-Za-z]/.test(dateValue[0]);
  const parsedDate = isStr
    ? date
    : !param
    ? formatTime(dateValue[1])
    : param === 'date'
    ? dateValue?.[0] || date
    : date;

  const [year, month, day] = parsedDate.split('-');

  switch (param) {
    case 'date':
      return `${month}/${day}/${year}`;
    case 'datetime':
      return format(parsedDate, 'MM/dd/yyyy HH:mm:ss');
    default:
      return parsedDate;
  }
}

export function removeDuplicatesFromArray<T>(arr: T[], key: keyof T): T[] {
  const uniqueArr = Array.from(new Set(arr.map((item) => JSON.stringify(item)))).map((item) =>
    JSON.parse(item)
  );
  const uniqueArrByKey = Array.from(new Map(uniqueArr.map((item) => [item[key], item])).values());

  return uniqueArrByKey;
}

export function removeNullOrEmptyValues<T extends Record<string, unknown>>(obj: T): Partial<T> {
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([, val]) => val !== null && val !== undefined && val !== '')
  );

  return filteredObj as Partial<T>;
}

export function validatePhoneNumber(phoneNumber: string) {
  const phoneRegex = /^(\+)?\d{11}$/;
  return phoneRegex.test(phoneNumber);
}

export function isPhoneNumberValid(phoneNumber: string, isSubmitBtnClicked: boolean) {
  const isValid = isSubmitBtnClicked
    ? !phoneNumber
      ? 'This is required'
      : validatePhoneNumber(phoneNumber)
      ? true
      : 'Invalid phone number'
    : true;

  return isValid;
}

export function tabTitle(newTitle: string) {
  return (document.title = newTitle + ' - oneXerp');
}

export function sortArrByKey<T>(array: T[], key: string): T[] {
  return array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
}

export function getPrice(param, val?: string) {
  let total = 0;
  param?.paymentschedules?.map((item) => {
    if (val == 'total') {
      if (item.status == 'Paid') {
        total += Number(item.amount);
      }
    } else if (val == 'remaining') {
      if (item.status == 'Paid') {
        total += Number(item.amount);
      }
    } else {
      if (item.status == 'Approved for Payment') {
        total += Number(item.amount);
      }
    }
  });
  if (val == 'remaining') {
    total = Number(param.po_total_price) - total;
  }
  return total;
}
export const getS3Path = (fileName: string, module: string) => {
  const currentDate = new Date();
  const yyyy = currentDate.getFullYear().toString();
  let mm = (currentDate.getMonth() + 1).toString();
  if (mm.length == 1) {
    mm = `0${mm}`;
  }
  let dd = currentDate.getDate().toString();
  if (dd.length == 1) {
    dd = `0${dd}`;
  }

  return `${module}/${yyyy}/${mm}/${dd}/${fileName}`;
};

export const getValidations = () => ({
  messages: {
    required: 'This is required'
  },
  validators: {
    phoneNumber: {
      message: 'Invalid phone number',
      rule: (val) => /^(\+)?\d{11}$/i.test(val),
      required: true
    }
  }
});

export const currencyFormat = (amount: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount ?? 0);

export const formatTime = (timeString: string) => {
  let time = '';
  if (timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    time = `${formattedHours}:${formattedMinutes} ${period}`;
  }

  return time;
};

export const ALPHA_NUMERIC_DASH_REGEX = new RegExp('^[A-Za-z0-9 ]*$');

export const MAX_10_DIGITS_ONE_DECIMAL_REGEX = /^\d{0,10}(\.\d{0,1})?$/;

export const MAX_11_DIGITS_TWO_DECIMALS_REGEX = /^\d{0,11}(\.\d{0,2})?$/;
export const PHONE_VALIDATION_REGEX = /^\d{0,10}$/;

export const TAX_VALIDATION_REGEX = /^(\d{2}-\d{7})$/;

export const HOURS_VALIDATION_REGEX = /^(|([0-9]|1[0-9]|2[0-4])(\.\d{0,1})?)$/;

export const INTEGER_VALIDATION_REGEX = /^[0-9]+$/;

export const PERCENTAGE_VALIDATION_REGEX = /^(100(\.00?)?|(\d{1,2}(\.\d{1,2})?)?)$/;

export const DECIMAL_VALIDATION_TWO_PLACES_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;

export const WEBSITE_VALIDATION_REGEX =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;

export const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const JOB_NAME_VALIDATION_REGEX = /^[A-Za-z0-9 \-()']*$/;

export const levenshteinDistance = (a: string, b: string): number => {
  let tmp;
  let i, j;
  const alen = a.length;
  const blen = b.length;
  const dist: number[][] = [];

  if (alen === 0) return blen;
  if (blen === 0) return alen;

  for (i = 0; i <= alen; i++) dist[i] = [i];
  for (j = 0; j <= blen; j++) dist[0][j] = j;

  for (i = 1; i <= alen; i++) {
    for (j = 1; j <= blen; j++) {
      tmp = a[i - 1] === b[j - 1] ? 0 : 1;
      dist[i][j] = Math.min(dist[i - 1][j] + 1, dist[i][j - 1] + 1, dist[i - 1][j - 1] + tmp);
    }
  }

  return dist[alen][blen];
};

export const roundToTwoDecimalPlaces = (amount: string | number): string => {
  const numericAmount = Number(amount);
  return numericAmount.toFixed(2);
};

export function getAssociatedJobs(param: AssociatedJob[]) {
  const jobIds: number[] = [];
  param.forEach((item: AssociatedJob) => jobIds.push(item.project_id));
  return jobIds.join(', ');
}

export function parseDateToDesiredTimezone(date) {
  const options = { timeZone: 'America/Chicago' };
  return date.toLocaleString('en-US', options);
}

export const getWeekStartAndEnd = (date: Date) => {
  const currentDay = date.getDay();
  const sundayOffset = currentDay;
  const saturdayOffset = currentDay - 6;
  const startOfWeek = new Date(date);
  const endOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - sundayOffset);
  endOfWeek.setDate(date.getDate() - saturdayOffset);
  return { startOfWeek, endOfWeek };
};

export const getPdf = (param) => {
  AWS.config.update({
    accessKeyId: localStorage.getItem('accessKeyId') ?? '',
    secretAccessKey: localStorage.getItem('secretAccessKey') ?? '',
    sessionToken: localStorage.getItem('sessionToken') ?? '',
    region: config.awsRegion
  });
  const s3 = new AWS.S3();
  const params = {
    Bucket: config.awsS3AssetsBucketName,
    Key: param.s3_uri ?? ''
  };

  s3.getObject(params, (err, data) => {
    if (err) {
      console.error(err);
    } else if (data.Body) {
      const pdfBlob = new Blob([data.Body as BlobPart], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank')?.focus();
    } else {
      console.error('No data.Body found');
    }
  });
};
