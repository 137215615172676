import { useState } from 'react';
import { useAdminWorkspaceStore } from 'store/index';
import Visibility from 'assets/images/visibility.svg';
import QBMessageModal from './QBMessageModal';
import EditIcon from 'assets/images/comments-icon.svg';
import { getJobById } from 'api/adminHub';
import { RotatingLines } from 'react-loader-spinner';
import DeleteIcon from 'assets/images/delete-icon.svg';
import DataTable from 'components/NewLayout/Table';

export default function JobAlerts({
  onClickDelete,
  onEdit
}: {
  onClickDelete: (id: string) => void;
  onEdit: () => void;
}) {
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const [selectedMsg, setSelectedMsg] = useState<string>('');
  const [showMsgModal, setShowMsgModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const jobAlerts = qbAlerts.filter((a) => ['UPDATE_JOB', 'CREATE_JOB'].includes(a.action_type));
  const setSelectedJob = useAdminWorkspaceStore((state) => state.setSelectedJob);

  const columns = [
    {
      prop: 'action_type',
      name: 'Action Type',
      data: 'action_type'
    },
    {
      prop: 'job_code',
      name: 'Job Assignment',
      data: (row) => {
        const body = row.message?.body;
        const actionType = row.action_type;
        return actionType === 'UPDATE_JOB'
          ? body?.NewJobCode ?? body?.JobCode ?? ''
          : body?.JobCode ?? '';
      }
    },
    {
      prop: 'last_updated_at',
      name: 'Last Updated',
      data: 'last_updated_at',
      type: 'date',
      dateFormat: 'date'
    },
    {
      prop: 'action',
      name: 'Action',
      data: (row) => (
        <>
          <img
            src={Visibility}
            width="30px"
            onClick={() => {
              setSelectedMsg(row);
              setShowMsgModal(true);
            }}
            className="img-fluid link-icon"
            alt="Visibility"
            title="View Message Details"
          />
          <img
            src={EditIcon}
            width="30px"
            onClick={() => onClickEdit(row)}
            className="img-fluid link-icon"
            alt="Edit Icon"
            title="Edit Job"
          />
          <img
            onClick={() => onClickDelete(row?.oneXerp_id)}
            src={DeleteIcon}
            className={'img-fluid link-icon'}
            alt="Del Icon"
          />
        </>
      )
    }
  ];

  const onClickEdit = (message) => {
    setLoading(true);
    getJobById(message?.oneXerp_id)
      .then((res) => {
        if (res.project_id) {
          const job = {
            ...res,
            actionType: res.quickbooks_id ? 'UPDATE_JOB' : 'CREATE_JOB'
          };
          setSelectedJob(job);
          localStorage.setItem('job', JSON.stringify(job));
          onEdit();
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return loading ? (
    <div style={{ width: '100%', margin: '5% auto', textAlign: 'center' }}>
      <RotatingLines
        strokeColor="grey"
        strokeWidth="2"
        animationDuration="0.99"
        width="60"
        visible={true}
      />
    </div>
  ) : (
    <>
      <DataTable items={jobAlerts} columns={columns} />
      {showMsgModal && (
        <QBMessageModal onClose={() => setShowMsgModal(false)} message={selectedMsg} />
      )}
    </>
  );
}
