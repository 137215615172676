import React, { useState, useEffect } from 'react';
import type { ReactElement } from 'react';
import MaterialTable from '@mui/material/Table';
import {
  Typography,
  Paper,
  Collapse,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  Box,
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material';
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { currencyFormat, dateFormatter } from 'utils/index';
import Loader from './Loader';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
interface DraggedItem {
  index: number;
  id: string;
}
interface Column<T> {
  key: keyof T;
  label: string;
  render?: (row: T) => React.ReactNode;
}
export default function DataTable<T, ST = undefined>(props: {
  items: T[];
  selectedRow?: T;
  rowCallback?: (row: T) => void;
  rowSelection?: boolean;
  columns: Column<T>;
  subColumns?: Column<T>;
  isSorting?: boolean;
  serverSidePagination?: boolean;
  dataSource?: T[];
  loading?: boolean;
  hidePagination?: boolean;
  totalCount?: number;
  rowVisible?: number;
  isSubRowsDraggable?: boolean;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: () => void;
  tableHeight?: string;
  rowsPerPage?: number;
  setPageSize?: (val: number) => void;
  setPageNo?: (val: number) => void;
}) {
  const [state, setState] = useState<{
    order: 'asc' | 'desc';
    orderBy: string;
    items: T[];
    page: number;
    rowsPerPage: number;
    itemsLength: number;
    selectedRow: T | '';
  }>({
    order: 'asc',
    orderBy: '',
    items: props.items,
    page: 0,
    rowsPerPage: props.rowsPerPage || 10,
    itemsLength: props.totalCount || props.items.length || 0,
    selectedRow: props.items && props.items.length > 0 ? props.items[0] : ''
  });

  const theme = useTheme();

  useEffect(() => {
    if (!deepEqual(props.items, state.items)) {
      const selectedRow = props.selectedRow ?? (props.items.length > 0 ? props.items[0] : '');
      setState((prevState) => ({
        ...prevState,
        items: props.items,
        selectedRow,
        itemsLength: props.totalCount || props.items.length
      }));
    }
  }, [props.items, props.rowCallback, props.totalCount]);

  const deepEqual = (a, b) => {
    if (a === b) return true;

    if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
      return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (const key of keysA) {
      if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
    }

    return true;
  };

  useEffect(() => {
    fetchData(state.page, state.rowsPerPage, '');
  }, []);

  const fetchData = (pageIndex: number, pageSize: number, searchText = '') => {
    if (props.serverSidePagination && props.dataSource) {
      props.dataSource((pageIndex + 1).toString(), pageSize.toString(), searchText);
    }
  };

  const renderName = (name) => {
    if (typeof name === 'function') {
      return name();
    } else {
      return name;
    }
  };

  const _getTableHeader = (isSub?: boolean) => {
    const { isSorting, columns, subColumns } = props;
    const cols = isSub ? subColumns : columns;

    return (
      <TableRow
        sx={{
          background: isSub ? '#e1e7ed' : theme.palette.primary.main,
          '> th': {
            borderRight: '1px solid #fff',
            color: isSub ? '#335D87' : '#fff',
            '.MuiCheckbox-root': {
              color: '#fff',
              '&.Mui-checked': {
                color: '#fff'
              }
            }
          }
        }}>
        {cols?.map((column, index) =>
          isSorting && column.isSort ? (
            <TableCell key={index} sx={{ whiteSpace: 'nowrap' }}>
              <TableSortLabel
                active={state.orderBy === column.prop}
                direction={state.order === 'asc' ? 'asc' : 'desc'}
                onClick={() => {
                  _createSortHandler(column.prop);
                }}>
                <Typography
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: '#fff'
                  }}>
                  {column.component && <column.component />}

                  {renderName(column.name)}

                  <Typography
                    component="div"
                    sx={{
                      marginRight: '5px',
                      float: 'right'
                    }}>
                    {column.colEndComponent && <column.colEndComponent />}
                  </Typography>
                </Typography>
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={index} sx={{ whiteSpace: 'nowrap' }}>
              <Typography component="div" sx={{ fontWeight: 'bold' }}>
                {column.component && <column.component />}

                {renderName(column.name)}

                <Typography component="div" sx={{ marginRight: '5px', float: 'right' }}>
                  {column.colEndComponent && <column.colEndComponent />}
                </Typography>
              </Typography>
            </TableCell>
          )
        )}
      </TableRow>
    );
  };

  const truncateTextWithTooltip = (text: string) => {
    if (text && typeof text === 'string' && text.length > 25) {
      return (
        <Tooltip title={text}>
          <span>{text.substring(0, 20).trim()}...</span>
        </Tooltip>
      );
    }
    return text;
  };

  const getSubRows = (row: ST, rowIndex: number) =>
    props.subColumns?.map((column, index1) => {
      if (typeof column.data === 'function') {
        if (column.type === 'date') {
          return (
            <TableCell key={index1}>
              {dateFormatter(column.data(row, `${rowIndex}-${index1}`), column.dateFormat)}
            </TableCell>
          );
        } else if (column.type === 'amount') {
          return (
            <TableCell key={index1}>
              {currencyFormat(parseInt(column.data(row, `${rowIndex}-${index1}`).toString()))}
            </TableCell>
          );
        } else {
          return (
            <TableCell key={index1}>
              {truncateTextWithTooltip(column.data(row, `${rowIndex}-${index1}`))}
            </TableCell>
          );
        }
      } else {
        if (column.type === 'date') {
          return (
            <TableCell key={index1}>
              {row[column.data] && dateFormatter(row[column.data], column.dateFormat)}
            </TableCell>
          );
        } else if (typeof column.type === 'function') {
          return (
            <TableCell key={index1}>
              {truncateTextWithTooltip(column.data(row, `${rowIndex}-${index1}`))}
            </TableCell>
          );
        } else if (column.type === 'amount') {
          return <TableCell key={index1}>{currencyFormat(row[column.data])}</TableCell>;
        } else {
          return <TableCell key={index1}>{truncateTextWithTooltip(row[column.data])} </TableCell>;
        }
      }
    });

  const _getTableRows = () => {
    const { columns, serverSidePagination } = props;
    const { items, order, orderBy, rowsPerPage, page } = state;

    if (items && items.length > 0) {
      const sortItems = items.sort(_getSorting(order || 'asc', orderBy));
      const filterItems = serverSidePagination
        ? sortItems
        : sortItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

      return (
        <>
          {filterItems?.map((row, index) => (
            <React.Fragment key={row?.id || `row-${index}`}>
              <TableRow
                sx={{
                  cursor: props.rowSelection ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: props.rowVisible == index ? '#F6DFE6' : ''
                  },
                  backgroundColor: props.rowVisible == index ? '#F6DFE6' : ''
                }}
                className="body-tr"
                key={index}
                onClick={() => _onRowClick(row)}>
                {columns?.map((column, index1) => {
                  if (typeof column.data === 'function') {
                    if (column.type === 'date') {
                      return (
                        <TableCell key={index1}>
                          {dateFormatter(column.data(row, `${index}-${index1}`), column.dateFormat)}
                        </TableCell>
                      );
                    } else if (column.type === 'amount') {
                      return (
                        <TableCell key={index1}>
                          {currencyFormat(
                            parseInt(column.data(row, `${index}-${index1}`).toString())
                          )}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={index1}>
                          {truncateTextWithTooltip(column.data(row, `${index}-${index1}`))}
                        </TableCell>
                      );
                    }
                  } else {
                    if (column.type === 'date') {
                      return (
                        <TableCell key={index1}>
                          {row[column.data] && dateFormatter(row[column.data], column.dateFormat)}
                        </TableCell>
                      );
                    } else if (typeof column.type === 'function') {
                      return (
                        <TableCell key={index1}>{column.data(row, `${index}-${index1}`)}</TableCell>
                      );
                    } else if (column.type === 'amount') {
                      return <TableCell key={index1}>{currencyFormat(row[column.data])}</TableCell>;
                    } else {
                      return (
                        <TableCell key={index1}>
                          {truncateTextWithTooltip(row[column.data])}{' '}
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              <TableRow className="sub-table" key={`sub-${row?.id || index}`}>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={columns.length}
                  sx={{ padding: '0 !important' }}>
                  <Collapse in={props.rowVisible === index} timeout="auto" unmountOnExit>
                    <MaterialTable>
                      <TableHead>{_getTableHeader(true)}</TableHead>
                      <DndProvider backend={HTML5Backend}>
                        <TableBody sx={{ borderLeft: '4px solid #39618a', margin: '20px' }}>
                          {row?.subRows?.map((subRow, index) =>
                            props.isSubRowsDraggable && props.moveRow && props.onDrop ? (
                              <DraggableTableRow
                                key={subRow.id}
                                id={subRow.id}
                                index={index}
                                moveRow={props.moveRow}
                                onDrop={props.onDrop}>
                                {getSubRows(subRow, index)}
                              </DraggableTableRow>
                            ) : (
                              <TableRow className="body-tr" key={subRow.id}>
                                {getSubRows(subRow, index)}
                              </TableRow>
                            )
                          )}
                          {(!row?.subRows || row?.subRows?.length == 0) && (
                            <TableRow>
                              <TableCell colSpan={props?.subColumns?.length}>
                                <Typography variant="subtitle2" gutterBottom textAlign="center">
                                  No data found!
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </DndProvider>
                    </MaterialTable>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const _onRowClick = (row: T) => {
    if (props.items.length > 0 && props.rowCallback) {
      props.rowCallback(row);
      setState((prevState) => ({
        ...prevState,
        selectedRow: row
      }));
    }
  };

  const _createSortHandler = (property) => {
    const { serverSidePagination } = props;
    const order = state.order === 'asc' ? 'desc' : 'asc';
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: property,
      items: !serverSidePagination
        ? state.items.sort(_getSorting(state?.order || 'asc', property))
        : []
    }));
    if (serverSidePagination) {
      fetchData(0, state.rowsPerPage, ''); //property, order)
    }
  };

  const _getSorting = (order: 'desc' | 'asc', orderBy: string) =>
    order === 'desc' ? (a, b) => _sort(a, b, orderBy) : (a, b) => -_sort(a, b, orderBy);

  const _sort = (a, b, orderBy) => {
    const prop1 = _getPropsToSort(a, orderBy);
    const prop2 = _getPropsToSort(b, orderBy);

    // Check if the properties are valid date objects and handle date comparison
    const isDate1 = prop1 instanceof Date && !isNaN(prop1.getTime());
    const isDate2 = prop2 instanceof Date && !isNaN(prop2.getTime());

    if (isDate1 && isDate2) {
      // Compare as dates
      return prop1.getTime() - prop2.getTime();
    } else {
      // Convert non-date properties to lowercase for case-insensitive string comparison
      const strProp1 = prop1?.toString().toLowerCase();
      const strProp2 = prop2?.toString().toLowerCase();

      if (strProp2 < strProp1) {
        return -1;
      }
      if (strProp2 > strProp1) {
        return 1;
      }
      return 0;
    }
  };

  const _getPropsToSort = (obj, orderBy: string) => {
    const column = props.columns.find((col) => col.prop === orderBy);
    // **Updated code to ensure `data` is used for sorting**
    const value = column && typeof column.data === 'function' ? column.data(obj) : obj[orderBy];

    // Handle date strings shown as "MM/DD/YYYY" and convert to Date objects
    if (
      column &&
      column.type === 'date' &&
      typeof value === 'string' &&
      /^\d{2}\/\d{2}\/\d{4}$/.test(value)
    ) {
      const [month, day, year] = value.split('/').map(Number);
      const parsedDate = new Date(year, month - 1, day);
      return !isNaN(parsedDate.getTime()) ? parsedDate : value;
    }

    // Handle ISO date strings coming from the database
    if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      return new Date(value);
    }

    // Convert currency strings to numbers (e.g., "$1010.00" -> 1010.00)
    if (typeof value === 'string' && value.trim().startsWith('$')) {
      const numericValue = parseFloat(value.replace(/[$,]/g, ''));
      if (!isNaN(numericValue)) {
        return numericValue;
      }
    }

    // Convert numeric strings to numbers
    if (
      typeof value === 'string' &&
      !isNaN(Number(value)) &&
      parseFloat(value).toString() === value
    ) {
      return parseFloat(value);
    }

    // Return the transformed value or the original prop value if data function is not defined
    return value;
  };

  const _handleChangePage = (page: number) => {
    if (props.serverSidePagination) {
      fetchData(page, state.rowsPerPage, ''); // state.orderBy, state.order, );
    }
    setState((prevState) => ({
      ...prevState,
      page
    }));

    props.setPageNo && props.setPageNo(page);
  };

  const _handleChangeRowsPerPage = (event) => {
    if (props.serverSidePagination) {
      fetchData(0, event.target.value, ''); //state.orderBy, state.order);
    }
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: event.target.value,
      page: 0
    }));

    props.setPageSize && props.setPageSize(event.target.value);
  };

  const getLastPage = () => {
    const totalItems = state.itemsLength > 10 ? state.itemsLength - 1 : state.itemsLength;
    return Math.floor(totalItems / state.rowsPerPage);
  };

  return (
    <div style={{ overflowX: 'auto', margin: 0 }}>
      <Paper>
        <MaterialTable>
          <TableHead>{_getTableHeader()}</TableHead>
          <TableBody>
            {_getTableRows()}
            {!props.loading && state.itemsLength == 0 && (
              <TableRow>
                <TableCell colSpan={props.columns.length}>
                  <Typography variant="subtitle2" gutterBottom textAlign="center">
                    No data found!
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>

        {props.loading ? <Loader /> : null}
      </Paper>

      <Box sx={{ position: 'sticky', left: '0px' }}>
        {!props.hidePagination && (
          <TablePagination
            component="div"
            SelectProps={{
              IconComponent: (props) => (
                <KeyboardArrowDown {...props} sx={{ color: '#fff !important' }} />
              )
            }}
            sx={{
              background: theme.palette.primary.main,
              '& .MuiToolbar-root': {
                display: 'flex',
                alignItems: 'baseline',
                marginTop: '20px',
                float: 'right',
                color: '#fff',
                width: '100%'
              },
              '> button': {
                border: 'none',
                color: '#fff'
              }
            }}
            count={state.itemsLength}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            onPageChange={(e, page) => _handleChangePage(page)}
            onRowsPerPageChange={_handleChangeRowsPerPage}
            ActionsComponent={() => (
              <Box
                sx={{
                  flexShrink: 0,
                  ml: 2.5,
                  svg: {
                    color: '#fff !important'
                  }
                }}>
                <IconButton
                  onClick={() => _handleChangePage(0)}
                  disabled={state.page === 0}
                  aria-label="first page">
                  <FirstPage />
                </IconButton>
                <IconButton
                  onClick={() => _handleChangePage(state.page - 1)}
                  disabled={state.page === 0}
                  aria-label="previous page">
                  <NavigateBefore />
                </IconButton>
                <IconButton
                  onClick={() => _handleChangePage(state.page + 1)}
                  disabled={state.page === getLastPage()}
                  aria-label="next page">
                  <NavigateNext />
                </IconButton>
                <IconButton
                  onClick={() => _handleChangePage(getLastPage())}
                  disabled={state.page === getLastPage()}
                  aria-label="last page">
                  <LastPage />
                </IconButton>
              </Box>
            )}
          />
        )}
      </Box>
    </div>
  );
}

const DraggableTableRow = ({
  id,
  index,
  moveRow,
  children,
  onDrop
}: {
  id: number | string;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  children: ReactElement;
  onDrop: () => void;
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ROW',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: 'ROW',
    hover: (item: DraggedItem) => {
      if (item.index !== index) {
        moveRow(item.index, index);
        item.index = index;
      }
    },
    drop: () => {
      onDrop();
    }
  });
  const opacity = isDragging ? 0.5 : 1;
  return (
    <TableRow
      className="body-tr"
      ref={(node) => drag(drop(node))}
      style={{ opacity, transition: 'opacity 0.3s ease' }}>
      {children}
    </TableRow>
  );
};
